import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';

import { useMsal, useAccount } from "@azure/msal-react";
import { silentRequest } from "../../../../../authConfig";

import { useHistory, useLocation } from 'react-router-dom';

import { 
    Stack,
    Icon,
    PrimaryButton,
    DefaultButton,
    TextField,
    FocusTrapZone,
    Spinner
} from '@fluentui/react';

import { useDropzone } from 'react-dropzone';
import styled from 'styled-components';

import {Buffer} from 'buffer';

var forge = require('node-forge');

// const API_URL = process.env.REACT_APP_API_URL;
const ADMIN_API_URL = process.env.REACT_APP_ADMIN_API_URL;

//Dropzone Style

const getColor = (props) => {
    if (props.isDragAccept) {
        return '#00e676';
    }
    if (props.isDragReject) {
        return '#ff1744';
    }
    if (props.isDragActive) {
        return '#2196f3';
    }
    //return '#eeeeee';
    //return 'rgb(194, 194, 194)';
    return 'rgb(134, 134, 134)';
}

const Container = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border-width: 2px;
    border-radius: 2px;
    border-color: ${props => getColor(props)};
    border-style: dashed;    
    background-color: #ffffff;
    color: #bdbdbd;
    outline: none;
    transition: border .24s ease-in-out;
    margin-top: 5px;
`;

// Processing dialog style
const spinnerStyles = {
    circle: {
      height: 56,
      width: 56,
      borderWidth: 4
    }
}

const P12 = () => {

    const { instance, accounts } = useMsal();
    const account = useAccount(accounts[0] || {});

    const history = useHistory();    
    const location = useLocation();

    const [data] = useState(location.state);

    const [selectedFile, setSelectedFile] = useState(null);
    const [loadP12Complete, setLoadP12Complete] = useState(false);
    const [password, setPassword] = useState('');
    const [passwordError, setPasswordError] = useState('');

    const [isLoading, setIsLoading] = useState(false);
    const [uploadStatus, setUploadStatus] = useState('');

    useEffect(() => {
        console.log('useEffect data call!: ', data);

        if (data !== undefined) {
            //console.log('useEffect data call! not undefied.');

            console.log('data: ', data);



        } else {
            history.push({ pathname: '/account', state: {} });
        }

    }, [data]);

    const FileDropzone = () => {
        //Dropzone
        const {
            getRootProps,
            getInputProps,
            isDragActive,
            isDragAccept,
            isDragReject,
            open,
            acceptedFiles,        
        } = useDropzone({
                accept: '.pfx, .p12, application/x-pkcs12, application/pkcs12', 
                // Disable click and keydown behavior
                noClick: true,
                noKeyboard: true,
                multiple: false,
                minSize: 0,
                maxSize: 20971520,
                onDrop: files => {
                    console.log(files)
                    console.log('File[0]: ', files[0]);

                    
                    

                    if ((files[0].type === 'application/x-pkcs12') || (files[0].type === 'application/pkcs12')) {

                        setSelectedFile(files[0]);
                        console.log('Set selected file complete.');
                        
                        //setUrl(URL.createObjectURL(files[0]));                        

                        setLoadP12Complete(true);
                        //setExtension('pdf');

                    } 

                    

                }
            });

        return (
            <div>           
            
                <div style={{ width: 700 }}>
                    <Container {...getRootProps({
                        isDragActive, 
                        isDragAccept, 
                        isDragReject,
                        //name: "content1",
                        /* 
                        onDrop: (file) => {
                                //this.handleFileSelect('content1', file)
                                console.log('Drop ', file);
                            }
                         */
                        
                    })}>
                        <input {...getInputProps()} />
                        <br/>
                        <div style={{ fontSize: '80px', color: 'rgba(45, 45, 83, 0.808)' }}><Icon iconName="Certificate" style={{ color: '#106ebe'}} /></div>
                        {/* <button className="up-btn" onClick={open}>{t("Choose File")}</button> */}
                        {/* <br/> */}
                        <Stack horizontal horizontalAlign="center">
                            <br/>
                            <PrimaryButton style={{ fontSize: "20px", height: "50px", width: "150px" }} onClick={open} text="เลือกไฟล์"/>
                            
                            <br/>
                            <br/>
                            <br/>
                            <br/>
                            
                        </Stack>
                        <Stack horizontal horizontalAlign="center">
                            <br/>
                            <br/>
                            
                            <span style={{ fontSize: "25px", color: "rgb(151, 151, 151)", fontWeight: "500" }}>วางไฟล์หรือคลิกที่นี่เพื่ออัปโหลด ไฟล์ .p12 หรือ .pfx</span>
                        </Stack>
                        

{/* 
                        <p>ลากไฟล์มาวางที่นี่ หรือคลิกปุ่มอัพโหลด</p>
                        <DefaultButton
                            text="อัพโหลด"
                            onClick={open}
                        />  
                         */}
                    </Container>
                </div>
            </div>
        );
    
    
    }

    const onDrop = useCallback(files => {
        // Do something with the files
        console.log(files);
        //setname(files.path)
        var reader = new FileReader();
        reader.onload = function(e) {
            var fileContent = e.target.result;
           
            // decrypt p12 using the password returned by getPassword(), the password should be protected and not hard coded 
            try {
                // get p12 as ASN.1 object
                var p12Asn1 = forge.asn1.fromDer(fileContent);
  
                var p12 = forge.pkcs12.pkcs12FromAsn1(p12Asn1, "leceiptleceipt" );
  
                 // get bags by type
                var certBags = p12.getBags({bagType: forge.pki.oids.certBag});
                var pkeyBags = p12.getBags({bagType: forge.pki.oids.pkcs8ShroudedKeyBag});
                // fetching certBag
                var certBag = certBags[forge.pki.oids.certBag][0];
                // fetching keyBag
                var keybag = pkeyBags[forge.pki.oids.pkcs8ShroudedKeyBag][0];
        
                if (certBag && keybag) {
                    console.log('on Drop cerBag :',certBag.cert);
                    console.log('Success then upload file!');
                    //setLoad(true)
                  
                }
  
            } catch(error) {           
                console.log(error);
                console.log('Fail!');
              
            };
        };
        reader.readAsBinaryString(files[0]);
    }, []);

    const {acceptfile, getRootProps, getInputProps} = useDropzone({onDrop});
    
    const openFile = () => {

        let reader = new FileReader();
        reader.onload = function(e) {
            let fileContent = e.target.result;
            //console.log('file content: ', fileContent);
            //setname(e.path)    
            
            // decrypt p12 using the password returned by getPassword(), the password should be protected and not hard coded 
            try {
                // get p12 as ASN.1 object
                var p12Asn1 = forge.asn1.fromDer(fileContent);

                var p12 = forge.pkcs12.pkcs12FromAsn1(p12Asn1, password );

                // get bags by type
                var certBags = p12.getBags({bagType: forge.pki.oids.certBag});
                var pkeyBags = p12.getBags({bagType: forge.pki.oids.pkcs8ShroudedKeyBag});
                // fetching certBag
                var certBag = certBags[forge.pki.oids.certBag][0];
                // fetching keyBag
                var keybag = pkeyBags[forge.pki.oids.pkcs8ShroudedKeyBag][0];
                // generate pem from private key
                //var privateKeyPem = forge.pki.privateKeyToPem(keybag.key);
                // generate pem from cert
                //var certificate = forge.pki.certificateToPem(certBag.cert);

                if (certBag && keybag) {
                    //console.log(certBag.cert);
                    console.log('Success then upload file!');
                    //setStatus('Loading..');
                    

                    setIsLoading(true);
                    //setUploadStatus('fail');

                    uploadFile();
                
                
                }

            } catch(error) {           
                console.log(error);
                console.log('Can not open file.');
                setPasswordError("รหัสเปิดไฟล์ไม่ถูกต้อง กรุณาลองอีกครั้ง...");
                
            };            

        };

        reader.readAsBinaryString(selectedFile);

    }

    const uploadFile = () => {

        instance.acquireTokenSilent({...silentRequest, account: account}).then(tokenResponse => {
      
            console.log('Access token: ', tokenResponse.accessToken);
            console.log('Token: ', tokenResponse);
      
            let promises = [];
      
            //Encode base64            
            let buff = Buffer.from(password);
            let base64pwd = buff.toString('base64');
      
            //files.forEach(file => {
      
                //console.log(file.path);
                let formData = new FormData();
                //formData.append('requestId', requestId);
                formData.append('file', selectedFile);
                formData.append('password', base64pwd);
                //console.log(formData);
      
                promises.push(
                    axios.post(ADMIN_API_URL + '/certificates', formData, {
                        headers: {
                            'Authorization': 'Bearer ' + tokenResponse.accessToken,
                            'Content-Type': 'multipart/form-data',
                            'User-Oid': data.oid,
                            'User-Cid': data.companyId
                            //'OID': 'b5cf38c9-5e52-4bd3-b737-caf5c6d30635'
                            //'api-key': '6c8921222ff946479e523edeca961f51'
                        }
                    })
      
                );        
      
      
            //});
      
            Promise.all(promises).then(function (results) {
      
            
                results.forEach(function (response) {
                    console.log('Response upload: ', response.data); 
                    //setLoad(false)
                    //setPass(false)
                    //setPwd('')
                    //setStatus('Upload .pfx/.p12 file success!');
                    //Message()
                    
                    if (response.data.id) {
                        setIsLoading(false);
                        setUploadStatus('successful');

                    } else {
                        setIsLoading(false);
                        setUploadStatus('fail');
                    }
                    
                    
      
      
                });                
          
            });
      
        }).catch(error => {           
        
            //Acquire token silent failure, and send an interactive request
            console.log(error);
            // instance.acquireTokenRedirect(silentRequest);
      
        });
      
    }

    const clearData = () => {
        setSelectedFile(null);
        setIsLoading(false);
        setUploadStatus('');
        setPassword('');
        setPasswordError('');
        setLoadP12Complete(false);

    }

    const handleSubmit = (e) => {

        e.preventDefault();   

        console.log('password: ', password);
        console.log('selected file: ', selectedFile);

        openFile();
  

    }

    return (
        <Stack>
           

                <h1 style={{ marginLeft: '0px', marginTop: '27px' }} ><Icon iconName="Certificate" style={{ color: '#106ebe'}} /> ใบรับรองอิเล็กทรอนิกส์</h1>
                <h3 style={{ marginTop: '0px', marginBottom: '10px' }} >ใบรับรองอิเล็กทรอนิกส์จาก TDID หรือ INET</h3>
                <br/>
                ชื่อบริษัท: {data.companyName}
                <br/>
                {/* ชื่อ: {data.firstName} {data.lastName} */}
                {/* <br/> */}
                อีเมล: {data.email}
                <br/>
                <br/>
                companyId: {data.companyId}                                
                <br/>
                oid: {data.oid}                                
                <br/>
                <br/>
                <br/>

                {!loadP12Complete && !isLoading &&
                    <Stack>

                        <FileDropzone />

                        <br/>
                        <br/>
                        <br/>
                        
                        <Stack horizontal horizontalAlign="center">
                            <DefaultButton 
                                style={{ fontSize: "20px", height: "50px" }}
                                onClick={ () => {
                                    //setClickAdd(true) 

                                    history.push({
                                        pathname: '/accounts/prepaid',
                                        //search: '?update=true',  // query string
                                        state: {}
                                    })
                                } 
                            }
                            >
                                ย้อนกลับ
                            </DefaultButton>
                        </Stack>

                    </Stack>
                }

                {loadP12Complete && !isLoading && (uploadStatus === '')  && 
                    <FocusTrapZone disabled={false}>
                        <Stack horizontalAlign="center" >
                            <br/>
                            {/* <br/> */}
                            {/* <Stack style={{ border: "1px solid rgb(235, 235, 235)", width: "350px", backgroundColor: "rgb(255, 252, 248)" }}> */}
                            <Stack style={{ border: "1px solid rgb(134, 134, 134)", width: "350px", backgroundColor: "#FFFFFF" }}>
                            <br/>
                            <br/>

                            <Stack horizontal horizontalAlign="center">                                
                                <Icon iconName="AzureKeyVault" style={{ fontSize: "55px", color: '#106ebe'}} />                                 
                            </Stack>

                            <br/>                  
                            <form onSubmit={handleSubmit}>

                                <Stack horizontal horizontalAlign="center">

                                    <TextField
                                        label="รหัสสำหรับเปิดไฟล์ .p12 หรือ .pfx"
                                        type="password"
                                        canRevealPassword
                                        revealPasswordAriaLabel="แสดงรหัส"
                                        required
                                        onChange={ e => {
                                            setPassword(e.target.value);
                                            setPasswordError('');
                                        } } 
                                        value={password}
                                        errorMessage={passwordError}
                                    />

                                </Stack>

                                <br/>
                                <br/>
                                <br/>                                
                                <Stack horizontal horizontalAlign="center" tokens={{ childrenGap: "30px" }} >
                                    <PrimaryButton 
                                        style={{ fontSize: "20px", height: "50px" }}
                                        type="submit"
                                    
                                    >
                                        อัปโหลด
                                    </PrimaryButton>

                                    <DefaultButton 
                                        style={{ fontSize: "20px", height: "50px" }}
                                        onClick={ () => {
                                            //setClickAdd(true) 
    /* 
                                            history.push({
                                                pathname: '/certificates/add/p12',
                                                //search: '?update=true',  // query string
                                                state: {}
                                            });
                                            */
                                            //setLoadP12Complete(null);
                                            //setSelectedFile(null);
                                            clearData();
                                        } 
                                    }
                                    >
                                        ย้อนกลับ
                                    </DefaultButton>
                                </Stack>
                            </form> 
                            <br/>
                            <br/>

                            </Stack>
                        </Stack>

                    </FocusTrapZone>
                }

                {isLoading &&
                    <Stack>

                        <Stack horizontal horizontalAlign='center'> 

                            <br/>
                            <br/>
                            <div className="certificates-loading-center">
                            <Spinner /* size={SpinnerSize.large} */ label="กำลังดำเนินการ..." labelPosition="down" styles={spinnerStyles} />
                            </div>
                            <br/>
                            
                        </Stack>

                    </Stack>
                }

                { (uploadStatus === 'successful') &&
                /* { true && */
                    
                        <Stack horizontal horizontalAlign="center">
                            <Stack style={{ border: "1px solid rgb(134, 134, 134)", width: "300px", backgroundColor: "#FFFFFF" }}>
                                <br/>                               
                                <br/>
                                <Stack horizontal horizontalAlign='center'> 
                                    <h1 style={{ margin: '0px', color: '#0078D4' }}>สำเร็จ!</h1>
                                </Stack>
                                <br/>
                                <Stack horizontal horizontalAlign='center'> 

                                    <img  height="120" src="/images/success.gif" />                                  
                                    
                                </Stack>
                                <br/>
                                <br/>
                                <Stack horizontal horizontalAlign='center'> 
                                    
                                    <PrimaryButton 
                                        style={{ fontSize: "20px", height: "50px" }}
                                        onClick={ () => {
                                            //setClickAdd(true) 
                                            
                                            history.push({
                                                pathname: '/etax/certificates',
                                                //search: '?update=true',  // query string
                                                state: {
                                                  companyId: data.companyId,
                                                  oid: data.oid,
                                                  companyName: data.companyName,
                                                  email: data.email
                                                }
                                            });

                                            clearData();
                                           
                                            
                                        } 
                                    }
                                    >เลือกใบรับรอง</PrimaryButton>
                                </Stack>
                                <br/>
                                <br/>
                               {/*  <br/> */}
                            </Stack>
                        </Stack>
                }

                { (uploadStatus === 'fail') &&
                    <Stack horizontal horizontalAlign="center">
                        <Stack style={{ border: "1px solid rgb(134, 134, 134)", width: "300px", backgroundColor: "#FFFFFF" }}>
                            <br/>                               
                            <br/>
                            <Stack horizontal horizontalAlign='center'> 
                                <h1 style={{ margin: '0px', color: '#F25139' }}>ไม่สำเร็จ!</h1>
                            </Stack>
                            <br/>
                            <Stack horizontal horizontalAlign='center'> 

                                <img  height="120" src="/images/fail01.gif" />                                  
                                
                            </Stack>
                            <br/>
                            <br/>
                            <Stack horizontal horizontalAlign='center'> 
                                
                                <PrimaryButton 
                                    style={{ fontSize: "20px", height: "50px" }}
                                    onClick={ () => {
                                        //setClickAdd(true) 
                                        clearData();
                                        history.push({
                                            pathname: '/etax/certificates/add/p12',
                                            //search: '?update=true',  // query string
                                            state: {}
                                        });
                                    
                                        
                                    } 
                                }
                                >ลองอีกครั้ง</PrimaryButton>
                            </Stack>
                            <br/>
                            <br/>
                        {/*  <br/> */}
                        </Stack>
                    </Stack>
                }
               
        </Stack>



    );

}

export default P12;